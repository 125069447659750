import React from "react";
import clsx from "clsx";
import LazyLoad from "react-lazyload";
import { graphql, Link } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { SectionLayout } from "~components/templates/section-layout";
import "./index.scss";
import { Interview } from "~components/utils/interview";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import { Button } from "~components/utils/button";
import { AssessmentArticleList } from "~components/articles/assessment-article-list";

type Props = {
  data: GatsbyAssessmentPenetrationTestPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <Assessment2PaneLayout
      formId={CONTACT_FORM_ID}
      meta={{
        title: "ペネトレーションテスト",
        description:
          "お客様の社内ネットワークやプロダクトなどに対して、攻撃者に攻撃されてシステムへの侵入・特権の奪取といった目標を達成されてしまうリスクの有無を検証するセキュリティテストです。専門のセキュリティエンジニアが環境に合わせた様々な技術を用いて擬似的に攻撃することで、システムや組織内に内在するリスクを見つけ出します。",
      }}
    >
      <div className="PenetrationTest AssessmentCommonPage">
        <LazyLoad>
          <div className="AssessmentCommonTop PenetrationTestTop">
            <div className="AssessmentCommonTop_Wrapper">
              <SectionLayout>
                <h1 className="AssessmentCommonTop_Title">
                  ペネレーションテスト
                </h1>
                <div className="AssessmentCommonTop_Body">
                  お客様の社内ネットワークやプロダクトなどに対して、攻撃者に攻撃されてシステムへの侵入・特権の奪取といった目標を達成されてしまうリスクの有無を検証するセキュリティテストです。専門のセキュリティエンジニアが環境に合わせた様々な技術を用いて擬似的に攻撃することで、システムや組織内に内在するリスクを見つけ出します。
                </div>
              </SectionLayout>
            </div>
          </div>
        </LazyLoad>

        <BreadCrumbs title="ペネトレーションテスト" />

        <LazyLoad>
          <div className="PenetrationTest_TextWrapper">
            <h2>Flatt Securityのペネトレーションテスト</h2>
            <img
              className="PenetrationTest_MeritImage"
              src={data?.pentest1?.publicURL || ""}
              alt=""
            />
            <h3>実際に攻撃者が目標を達成できるのかを明らかにできる</h3>
            <p>
              {`実際の攻撃者を想定して擬似的に攻撃することで、どこまで侵入できるのか、どのような攻撃が出来るのかを検証し、重要なサーバへの不正アクセスといった攻撃の目標を達成できるのかを明らかにすることができます。

                  攻撃者としての能力が高い専門のセキュリティエンジニアがテスターとなり、システムにある悪用可能な脆弱性や設計の不備などを狙うことで精度の高いペネトレーションテストを実施します。`}
            </p>
            <img
              className="PenetrationTest_MeritImage"
              src={data?.pentest2?.publicURL || ""}
              alt=""
            />
            <h3>攻撃に利用されうる脆弱性を検知できる</h3>
            <p>
              ペネトレーションテストでは攻撃の目標を達成するために脆弱性を利用します。そのため、攻撃者に利用されうる脆弱性を検知することができます。ソーシャルエンジニアリングを用いることもあり、システムの脆弱性だけでなく、人や組織から生まれる脆弱性を検知できることも可能です。
            </p>
            <img
              className="PenetrationTest_MeritImage"
              src={data?.pentest3?.publicURL || ""}
              alt=""
            />
            <h3>
              導入しているセキュリティ対策が総合的に機能しているのか確認できる
            </h3>
            <p>
              擬似的にシステムを攻撃することで、現在導入しているセキュリティ対策が総合的に機能しているのかを確認することができます。システム上の脆弱性を利用して攻撃するだけでなく、機密情報の管理体制といった運用上のミスを利用することもあります。そのため、導入しているセキュリティ製品や組織内のルールなどのセキュリティ対策の総合力を評価し、役に立っていないセキュリティ対策や形骸化したルールなども見直すことができます。
            </p>
            <img
              className="PenetrationTest_MeritImage"
              src={data?.pentest4?.publicURL || ""}
              alt=""
            />
            <h3>柔軟なテストを実施</h3>
            <p>
              {`ブラックボックステストやホワイトボックステスト、システムの外部を攻撃起点としたテストや内部を攻撃起点としたテストなど様々な形態、手法で柔軟なテストを実施します。
                  事前のヒアリングでお客様のご要望や必要性に合わせたテスト内容を決定し、最適なテストプランをご提案いたします。`}
            </p>
          </div>
        </LazyLoad>

        <LazyLoad>
          <div className="Process">
            <div className="Process_Wrapper">
              <h2 className="Process_Heading">診断の手順</h2>
              <ul className="Process_StepList">
                <li className="Process_StepWrapper">
                  <div className="Process_Step">
                    <h3 className="Process_Number">1</h3>
                    <div className="Process_Container">
                      <h2 className="Process_SubHeading">テスト準備</h2>
                      <div className="Process_Note note">
                        NDA締結後、事前ヒアリングを行い、最適なテストプランをご提案いたします。具体的には、お客様の希望する条件やコスト、システムに関して提供していただく情報などに基づいて、テストを実施する対象システムの範囲や実施する攻撃のシナリオを決定します。
                        <br />
                        <br />
                        たとえば、重要なサーバへの不正アクセスや管理者権限の奪取といった目標を設定し、目標を達成するまでの起点や工程を設定します。また、テストによってシステムに障害が発生し、お客様の実務に影響が出る可能性があります。そのリスクを最小限にするため、テスト実施における禁止事項も設定します。上記の通り、テストプランや禁止事項、スケジュールを決定し、お見積もり金額をご確認いただいた上で契約を締結します。
                      </div>
                    </div>
                  </div>
                  <img
                    className="Process_Triangle"
                    src={data?.triangle?.publicURL || ""}
                    alt=""
                  />
                </li>
                <li className="Process_StepWrapper">
                  <div className="Process_Step">
                    <h3 className="Process_Number">2</h3>
                    <div className="Process_Container">
                      <h2 className="Process_SubHeading">テスト実施</h2>
                      <div className="Process_Note note">
                        専門のセキュリティエンジニアが、実際の攻撃者が用いる攻撃手法と同等の攻撃を利用し、攻撃シナリオに沿ってテストを実施します。お客様の実務への影響を少なくするために、場合によってはお客様と連絡を取り合いながらテストを実施することもあります。
                      </div>
                    </div>
                  </div>
                  <img
                    className="Process_Triangle"
                    src={data?.triangle?.publicURL || ""}
                    alt=""
                  />
                </li>
                <li className="Process_StepWrapper">
                  <div className="Process_Step">
                    <h3 className="Process_Number">3</h3>
                    <div className="Process_Container">
                      <h2 className="Process_SubHeading">テスト実施後</h2>
                      <div className="Process_Note note">
                        実施結果をもとに作成した報告書を納品します。報告書には主にシナリオやテスト実施範囲などのテスト概要、テストで検出された脆弱性、今後の対策方法などを記載します。
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </LazyLoad>

        <LazyLoad>
          <div className="Fee">
            <h2 className="Fee_Header">料金</h2>
            <div className="Fee_Wrapper wow fadeInUp">
              <h4 className="Fee_Title">ペネトレーションテスト</h4>
              <h2 className="Fee_Price">
                500
                <span className="Fee_SmallText">万円から</span>
              </h2>
            </div>
            <div className="PenetrationTest_TextWrapper">
              <p>
                ペネトレーションテストは、対象とするシステムの規模や範囲、作業期間や掛ける労力によって費用が変わります。
                一般的には、ペネトレーションテストの方が、より高度な技術が必要な分、脆弱性診断に比べて費用・期間ともに高コストになります。
                <br />
                <br />
                実施期間については1週間〜数ヶ月と、設定されたゴールや予算などによって大きく変わります。
              </p>
            </div>
          </div>
        </LazyLoad>

        <LazyLoad>
          <div className="PenetrationTest_TextWrapper PenetrationTest_Difference">
            <h2>脆弱性診断との違い</h2>
            <p>
              ペネトレーションテストと脆弱性診断は混同、誤解されていることがあります。脆弱性診断とはシステムにおける脆弱性を網羅的に洗い出すことを目的としたセキュリティテストです。項目別の違いを以下の表にまとめました。
            </p>
            <table>
              <tr>
                <th />
                <th>
                  ペネトレーション
                  <br className="sp" />
                  テスト
                </th>
                <th>脆弱性診断</th>
              </tr>
              <tr>
                <td>目的</td>
                <td>
                  実際に脆弱性を悪用して、攻撃者が目的を達成出来るのかを検証する
                </td>
                <td>システムの脆弱性を網羅的に洗い出す</td>
              </tr>
              <tr>
                <td>方法</td>
                <td>
                  実際の攻撃者と同じツール、脆弱性等を利用して、侵入、攻撃が可能かを試行する
                </td>
                <td>
                  専用のツールと手動での調査で、ガイドライン等を基準に脆弱性の有無を確認する
                </td>
              </tr>
              <tr>
                <td>報告書</td>
                <td>
                  擬似攻撃に用いた侵入経路や攻撃シナリオ、脆弱性などを記載
                </td>
                <td>発見された脆弱性やセキュリティ機能不足の一覧を記載</td>
              </tr>
            </table>
            <h3>目的の違い</h3>
            <p>
              {`ペネトレーションテストでは脆弱性を利用して、攻撃者がシナリオに沿って目標を達成できるのかを検証することを目的とします。

                  一方で、脆弱性診断では脆弱性の有無を網羅的に明らかにすることを主な目的とします。脆弱性を網羅的に洗い出すには脆弱性診断の方が有効と言えますが、実際の攻撃の被害や侵入経路を検証するにはペネトレーションテストが有効です。`}
            </p>
            <h3>方法の違い</h3>
            <p>
              {`ペネトレーションテストでは実際の攻撃者が用いる攻撃手法と同等の攻撃を利用して、攻撃者の目標が達成できるのかを検証します。

                  一方で、脆弱性診断では特定のガイドラインや基準をベースラインとして、診断対象がそのベースラインを遵守できているかを確認することで網羅的に脆弱性を調査します。ペネトレーションテストではソーシャルエンジニアリングを用いることや、機密情報の管理体制のミスを利用することもあるため、人や組織のルールなどもテスト対象になり得る点も特徴の一つです。`}
            </p>
            <h3>報告書の違い</h3>
            <p>
              脆弱性診断では発見された脆弱性やセキュリティ機能不足の一覧を記載をすべて報告書に記載します。一方で、ペネトレーションテストでは擬似攻撃に用いた侵入経路や攻撃シナリオ、脆弱性などを報告書に記載します。
            </p>
          </div>
          <AssessmentArticleList
            data={{
              articles: data.allContentfulArticle.nodes,
              category: data.contentfulArticleCategory,
            }}
          />
        </LazyLoad>
        <LazyLoad>
          <Interview title="ペネトレーションテストの事例インタビュー">
            <AssessmentVoiceList slugs={["blackbear", "folio"]} />
          </Interview>
        </LazyLoad>
        <h2 className="AssessmentCommonContact_Title">
          お見積もりは無料です。診断のご依頼はお気軽にお問い合わせください。
        </h2>
        <Link to="/contact">
          <div className="AssessmentCommonQuickContact">
            <Button buttonTheme="Secondary" buttonSize="Large">
              今すぐお問い合わせ
            </Button>
          </div>
        </Link>
      </div>
    </Assessment2PaneLayout>
  );
};

export const query = graphql`
  query AssessmentPenetrationTestPage {
    pentest1: file(relativePath: { eq: "assessment/pentest1.png" }) {
      publicURL
    }
    pentest2: file(relativePath: { eq: "assessment/pentest2.png" }) {
      publicURL
    }
    pentest3: file(relativePath: { eq: "assessment/pentest3.png" }) {
      publicURL
    }
    pentest4: file(relativePath: { eq: "assessment/pentest4.png" }) {
      publicURL
    }
    triangle: file(relativePath: { eq: "assessment/triangle.png" }) {
      publicURL
    }
    members_02: file(relativePath: { eq: "top/members2.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allContentfulArticle(
      filter: {
        category: { slug: { eq: "penetration_test" } }
        slug: { ne: "disabled" }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...AssessmentArticleListItemData
      }
    }
    contentfulArticleCategory(slug: { eq: "penetration_test" }) {
      ...AssessmentArticlaListCategory
    }
  }
`;

export default withContext(Component);
